import { createStore } from 'vuex'
// import http from '@/service/http'

export default createStore({

  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    id:83240,
    // baseUrl: 'https://api.elinnma.com/public/api/',
    // baseUrlImage: 'https://api.elinnma.com/public/'
     baseUrl: 'https://mobile.ar-bank.sd/api/',

  },
  getters: {
    authenticated(state) {
      return state.authenticated
    },
    user(state) {
      return state.user
    }
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },
    SET_ID(state, value) {
      console.log(state.id);
      state.id = value

    }
  },
  actions: {
    
    setId({commit},payload) {
      commit('SET_ID',payload)

    }
  },
  modules: {
  }
})
